<template>
    <el-dialog :visible.sync="dialogVisible" title="编辑菜单信息" width="30%">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px">
            <el-form-item label="菜单名称" prop="name">
                <el-input type="text" v-model.trim="ruleForm.name" placeholder="请输入菜单名称(2-4个字符)"></el-input>
            </el-form-item>
            <el-form-item label="跳转地址" prop="url">
                <el-input type="text" v-model.trim="ruleForm.url" placeholder="请输入跳转地址(2-18个字符)"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-switch v-model="ruleForm.status" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
        </el-form>
        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit('ruleForm')" v-loading.fullscreen.lock="fullscreenLoading">确 定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        name: "editDiolog",
        data(){
            return{
                dialogVisible:false,    //显示本组件
                fullscreenLoading: false,//loading框
                ruleForm: {},
                rules: {
                    name: [
                        { required: true, message: '请输入菜单名称', trigger: 'blur' },
                        { min: 2, max: 4, message: '长度在 2 到 4 个字符', trigger: 'blur' }
                    ],
                    url: [
                        { required: true, message: '请输入跳转地址', trigger: 'blur' },
                        { min: 1, max: 18, message: '长度在 1 到 18 个字符', trigger: 'blur' }
                    ],
                }
            }
        },
        methods:{
            //打开本组件
            open(row){
                this.ruleForm = JSON.parse(JSON.stringify(row));
                this.dialogVisible = true;
            },
            //提交表单
            submit(formName) {
                this.$refs[formName].validate(async valid => {
                    if (valid) {
                        this.fullscreenLoading = true;
                        try{
                            const {data} = await this.$axios({
                                method:'POST',
                                url:'/api/firsttitle/edit',
                                data:{
                                    id: this.ruleForm.id,
                                    name: this.ruleForm.name,
                                    url: this.ruleForm.url,
                                    status: this.ruleForm.status,
                                }
                            });
                            this.fullscreenLoading = false;
                            if(data.code){
                                this.$message({
                                    message: data.msg,
                                    type: 'success',
                                });
                                this.$emit('success');
                                this.dialogVisible = false;
                            }else{
                                this.$message.error(data.msg);
                            }
                        }catch (e) {
                            this.fullscreenLoading = false;
                            this.$message.error('网络错误，请稍后重试');
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        },
    }
</script>

<style scoped>

</style>
<template>
    <div class="navs">
        <div class="container">
            <div class="handle-box">
                <el-button type="primary" icon="el-icon-circle-plus-outline" class="handle-del mr10" @click="add">添加导航菜单</el-button>
            </div>
            <el-table v-loading="loading" border :data="tableData" :header-cell-style="{backgroundColor:'#f5f7fa'}">
                <el-table-column align="center" prop="name" label="菜单名称"></el-table-column>
                <el-table-column align="center" prop="url" label="跳转路径"></el-table-column>
                <el-table-column align="center" prop="status" label="状态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status==1" type="success" effect="dark">启用</el-tag>
                        <el-tag v-else type="danger" effect="dark">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" icon="el-icon-edit" type="success" size="small">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <Dialog ref="dialog" @success="getList" />
        <editDialog ref="editDialog" @success="getList" />
    </div>
</template>

<script>
    import Dialog from '@/components/Navs/dialog'
    import editDialog from '@/components/Navs/editDialog'
    export default {
        name: "Navs",
        components:{
            Dialog,
            editDialog,
        },
        data(){
            return{
                tableData:[],    //数据列表
                loading:true,   //loading框
            }
        },
        created(){
            this.getList();
        },
        methods:{
            //获取菜单数据
            async getList(){
                try{
                    const {data} = await this.$axios('/api/Ffirsttitle');
                    this.loading = false;
                    if(data.code){
                        this.tableData = data.firstTitle_ret;
                    }
                }catch (e) {
                    //
                }
            },
            //添加菜单
            add(){
                this.$refs.dialog.open();
            },
            //修改菜单信息
            handleEdit(row){
                this.$refs.editDialog.open(row);
            },
            //修改菜单状态
            changeStatus(){},
        },
    }
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }
</style>